<template>
  <main class="container">
    <div class="header h3 mt-2">
          <span class="header" v-if="row.contactDetailId"> Detalle Contrato / {{ padDigits(row.contactDetailId, 5)}}</span>
        <!-- <button class="btn-2 float-end" @click="showNewModal=true"><i class="fas fa-plus me-2"/> Crear Nuevo</button> -->
    </div>

    <!-- ADD NEW MODAL -->
    <b-modal id="modal-1" title="Contrato" class="p-3" centered v-model="showFotoModal" hide-footer>
        <form  class="mx-3">
            <img class="mb-4" style="max-height:500px" :key="fotoRefresh" :src="foto" />
                                    
            <button class="btn-3 float-end me-2" type="button" @click="showFotoModal=false">Cerrar <i class="fas fa-times ms-2"></i></button>
        </form>
    </b-modal>

    <!-- DETAILS -->
    <!-- <div class="row mt-2 mx-1" style="min-height:75vh; background:rgba(0,0,0,0.5); padding:20px"> -->
    <div class="row mt-2 " style="min-height:75vh; background:rgba(0,0,0,0.5); padding:20px">
        <div class="row">
            <!-- <div class="col-md-6">
                <label class="text-green-2 mt-2">Almacén de salida:</label>
                <select class="form-select border-secondary" required v-model="newItem.warehouseId" @change="selectWarehouse()">
                    <option :value="undefined" disabled>* seleccione</option>
                    <option :value="warehouse.id" v-for="warehouse in warehouses" :key="warehouse.id">{{warehouse.name}}</option>
                </select>            
            </div> -->
            <div class="col-md-6">
                <label class="text-white mt-2">Id Transferencia: {{row.transferId}}</label><br>
                <label class="text-white mt-2">Emisor: {{row.username}}</label><br>
                <label class="text-white mt-2">Sub-Estación: {{row.receiverStationName}}</label><br>
                <!-- <label class="text-white mt-2">Puesto que desempeña: {{row.employeeRole}}</label><br> -->
            </div>
            <div class="col-md-6">
                <label class="text-white mt-2">Fecha: {{$moment(row.creationDate).format('DD-MM-YYYY')}}</label><br>
                <label class="text-white mt-2">Responsable: {{ row.employeeMame}}</label><br>
                <label class="text-white mt-2">Número de identificación Tributaria: {{row.nit}}</label><br>
                <!-- <label class="text-white mt-2">Fecha de entrada: {{row.senderUserName}}</label> -->
            </div>
            <div class="row">
                <div class="col-md-6">
                    <button @click="showModal(row)" class="btn-1 ms-2" style="min-width:40px;;">Ver imagen <i class="fas fa-image"></i></button>
                </div>
            </div>
        </div>
        <div class="row" style="top:10px">
            <b-table 
            id="my-table"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields">
            
                 <template #cell(price)="data">
                    <div>                        
                        <span>{{'Q ' + data.item.price.toFixed(2)}}</span>
                    </div>
                </template>
                 <template #cell(fecha)="data">
                    <div>                        
                        <span>{{$moment(row.creationDate).format('DD-MM-YYYY')}}</span>
                    </div>
                </template>
             </b-table> 

            <b-pagination class="float-end" v-if="items.length > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>
        </div>
        <!-- <div class="row">
            <div class="col-md-12">
                <button class="btn-2 float-end mt-3" @click="print()">Imprimir &nbsp;&nbsp;<i class="fas fa-print me-2"/></button>
            </div>
        </div> -->
    </div>
  </main>
</template>

<script>
export default {
props:["id"],
computed: {
    rows() {
    return this.items.length
    }
},
data(){
    return {
        items:[],
        row:{},
        fields: [
            { key: "fecha", label:"Fecha de cargo"},
          { key: "code", label:"Código"},
          { key: "name", label:"Descripción"},
          { key: "observations", label:"Observaciones"},
          { key: "price", label:"Valor del bien"},
        //   { key: "acciones", tdClass:"text-end", thClass:"text-end pe-5"},          
        ],
        perPage: 10,
        currentPage: 1,

        showEditModal:false,
        table: 'transfers',
        transferId: 0,
        contactDetail:[],
        showFotoModal: false,
        foto: '',
        fotoRefresh: 0
    }
},
async mounted(){
    this.transferId = this.$route.params.id;
    await this.getData()
},
methods:{
    showModal(item){
        console.log(item)
        this.foto = item.picture
        this.fotoRefresh++
        this.showFotoModal = true
    },
    padDigits(number, digits) {
        return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
    },    
    print(){

    },
    async getData(){
        try {
            let res = await this.$store.dispatch('get', {path: this.table + '/getTransferContractDetails/'+ this.transferId});
            if (res){
                this.row = res.data[0]
                console.log(this.row)
                this.items = res.items
                //this.contactDetail = res.contactDetail[0]

            }
        } catch (error) {
            this.row = {}
            console.error('error', error)
        }
    }
     

}
}
</script>

<style>

</style>